import React from 'react';
import Page from 'react-page-loading'
import "../../_assets/css/app.css";
import IMAGE from '../../_assets/img/bg2.jpg'
import loginService from '../../_services/login.service';
import {currentUserSubject} from "../../_utils/currentuser";

class Login extends React.Component{
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state ={
            email_address: "",
            password: "",
            message: "",
            isLoading: false,
        }
    }
    handleChange = event => {this.setState({[event.target.name]: event.target.value});};
    onSubmit=(e)=>{
        e.preventDefault();
        this.setState({isLoading: true});
        if(this.state.email_address === "" || this.state.password === ""){
            this.setState({message: "All fields are required", isLoading: false})
        }else {
            loginService.user_login({email_address: this.state.email_address, password: this.state.password}).then(response => {
                     
                    if (response.data.response_code === 1) {
                        localStorage.setItem('currentUser', JSON.stringify(response.data));
                        localStorage.setItem('isLoggedIn', true);
                        localStorage.setItem('messageSaved', "Login Success");
                        currentUserSubject.next(response.data);
                        window.user = response.data;
                        alert("Login success")
                        window.setTimeout(function(){
                            window.location.href = "/dashboard";
                            this.setState({isLoading: false,});
                        }, 2000);
                    } else {
                        this.setState({message: 'Wrong Email or Password', isLoading: false});
                    }
                }).catch((error) => {
                  this.setState({isLoading: false})
                alert(error);
            });
            this.setState({password: '', email_address: ''});
        }
    }
    render(){
        return(
            <div>
    <Page loader={"bar"} color={"orange"} size={12}>
  <div id="app">
    <div className="page parallel">
      <div className="d-flex row">
        <div className="col-sm-3 white">
          <div className="p-5 mt-5 text-center">
            <h3><span style={{color: '#006189'}}>KCB</span>NILIPIE</h3>
          </div>
          <div className="p-5">
            <h3>Welcome to <br/> <b>KCBNILIPIE</b> Portal</h3>
            <p><small>It's easy to see usage, transactions, and do so much more, all in one place.</small></p>
            <form onSubmit={this.onSubmit}>
              <div className="form-group has-icon"><i className="icon-user-o" />
                <input type="email" name="email_address" value={this.state.email_address} className="form-control form-control-lg" placeholder="Email address" onChange={this.handleChange} />
              </div>
              <div className="form-group has-icon"><i className="icon-user-secret" />
                <input type="password" name="password" value={this.state.password} className="form-control form-control-lg" placeholder="Password" onChange={this.handleChange} />
              </div>
        <button className="btn btn-primary btn-lg btn-block"  style={{backgroundColor: '#006189'}} onSubmit={this.onSubmit}>{this.state.isLoading ? <i className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            /> : 'Log In'}</button>
            
            </form>
            <p style={{color: 'red', textAlign: 'center'}}>
              <b>{this.state.message}</b>
            </p>
          </div>
        </div>
        <div className="col-sm-9  height-full accent-3 align-self-center text-center d-none d-md-block" data-bg-repeat="false" data-bg-position="center" style={{backgroundColor: 'transparent', backgroundImage: `url${IMAGE}`}}>
          <img src ={IMAGE} height="100%" width="100%" style={{backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%'}}/>
        </div>
      </div>
    </div>
    <div className="control-sidebar-bg shadow white fixed"/>
  </div>
  </Page>
</div>

        )
    }
}

export default Login;