import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
const routing = Routes();

if(localStorage.currentUser) window.user=JSON.parse(localStorage.currentUser)

ReactDOM.render(routing,
  document.getElementById('root')
);

serviceWorker.unregister();
