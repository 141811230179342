import React from 'react';
import Page from 'react-page-loading'
import "../../_assets/css/app.css";
import reportService from '../../_services/report.service';
var fileDownload = require('js-file-download');

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            username: "",
            start_date: "",
            end_date: "",
            data: "",
            isLoading: false,
        }
    }

    handleChange = event => {this.setState({[event.target.name]: event.target.value});};

    componentDidMount(){
        var user = localStorage.getItem("currentUser");
        this.setState({username: JSON.parse(user).username});

        this.timeout = setTimeout(() => {
          window.location.href = "/";
         }, 50000);
    }

    onSubmit=(e)=>{
        e.preventDefault();
        this.setState({isLoading: true,})
        if(this.state.start_date === "" && this.state.end_date){
            alert("Start Date & End date is required");
        }else{
            reportService.generate_csv_report(this.state.start_date,  this.state.end_date).then(response => {
                    alert("Report has been generated")
                    this.setState({data: response.data}); 
                    var blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
                    fileDownload(blob,  'kcbnilipie.xls');
                    this.setState({isLoading: false,})
                    
        });
        this.setState({start_date: '', end_date: ''});
        }
    }
    logoutUser(){
      if(window.confirm("Are you sure you want to logout?")){
        window.location.href = "/";
      }
    }
    render(){
        return(
            <div>
              <Page loader={"bar"} color={"orange"} size={12}>
  <div id="app">
    <aside className="main-sidebar fixed offcanvas b-r sidebar-tabs" data-toggle="offcanvas">
      <div className="sidebar">
        <div className="d-flex hv-100 align-items-stretch">
          <div className="text-white"  style={{backgroundColor: '#006189'}}>
            <div className="nav mt-5 pt-5 flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"  style={{backgroundColor: '#006189'}}>
              <a className="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#" role="tab" aria-controls="v-pills-home" aria-selected="true"><i className="icon-inbox2" /></a>
              <a className="nav-link" id="v-pills-messages-tab" href="#"><i className="icon-message" /></a>
              <a className="nav-link" id="v-pills-messages-tab" onClick={this.logoutUser} title="Logout"><i className="icon-power-off" /></a>
            </div>
          </div>
          <div className="tab-content flex-grow-1" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
              <div className="relative brand-wrapper sticky b-b">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <div className="text-xs-center">
                    <span className="font-weight-lighter s-18">KCB | NILIPIE</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
              <div className="relative brand-wrapper sticky b-b p-3">
                <form>
                  <div className="form-group input-group-sm has-right-icon">
                    <input className="form-control form-control-sm light r-30" placeholder="Search" type="text" />
                    <i className="icon-search" />
                  </div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </aside>
    <div className="has-sidebar-left">
      <div className="pos-f-t">
        <div className="collapse" id="navbarToggleExternalContent">
          <div className="bg-dark pt-2 pb-2 pl-4 pr-2">
            <div className="search-bar">
              <input className="transparent s-24 text-white b-0 font-weight-lighter w-128 height-50" type="text" placeholder="start typing..." />
            </div>
            <a href="#" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" className="paper-nav-toggle paper-nav-white active "><i /></a>
          </div>
        </div>
      </div>
    </div>
    <a href="#" data-toggle="push-menu" className="paper-nav-toggle left ml-2 fixed">
      <i />
    </a>
    <div className="has-sidebar-left has-sidebar-tabs">
      {/*navbar*/}
      <div className="sticky">
        <div className="navbar navbar-expand d-flex justify-content-between bd-navbar white shadow">
          <div className="relative">
            <div className="d-flex">
              <div className="d-none d-md-block">
        <h1 className="nav-title">Welcome {this.state.username},</h1>
              </div>
            </div>
          </div>
          {/*Top Menu Start */}
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown custom-dropdown user user-menu ">
                <a href="#" className="nav-link" data-toggle="dropdown">
                  
                  <i className="icon-more_vert " />
                </a>
               
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <form id="needs-validation" onSubmit={this.onSubmit}>
            <div className="row" style={{marginTop: '20px'}}>
                <h5>Enter Start and end date to generate CSV Report</h5>
              <div className="col-md-9">
                <div className="row" style={{marginTop: '20px'}}>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom01">Start Date</label>
                    <input type="date" className="form-control"  value={this.state.start_date} name="start_date" id="validationCustom01" placeholder="Start date" onChange={this.handleChange} required />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="validationCustom02">End Date</label>
                    <input type="date" className="form-control" id="validationCustom02" value={this.state.end_date} name="end_date" placeholder="End Date" onChange={this.handleChange} required />
                  </div>
                </div>
                <div className="row">
                  
                  <div className="col-md-12 mb-12">
        <button className="btn btn-primary btn-md float-right" type="submit" onSubmit={this.onSubmit}  style={{backgroundColor: '#006189'}}>{this.state.isLoading ? <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />: "Generate Report"}</button>
                    
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  
  </div>
  </Page>
</div>

        )
    }
}
export default Dashboard;