import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Login from './_pages/login/index';
import Dashboard from './_pages/dashboard/index';
import Forget from './_pages/forget/index';

function Routes() {
    return (
        <Router basename={sessionStorage.getItem("isLoggedIn") ? sessionStorage.getItem('currentUser').first_name : null}>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/dashboard" exact component={Dashboard}/>
                <Route path="/forget-password" exact component={Forget}/>
            </Switch>

        </Router>

    );
}

export default Routes;
