import axios from 'axios';
import URL from "../_utils/url";

export default class ReportService {
    static generate_csv_report(start_date, end_date) {
        return axios.get(URL + `kcb-reports/?start_date=${start_date}&end_date=${end_date}`, {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'blob'});
    }

}

