import axios from 'axios';
import URL from "../_utils/url";

export default class LoginService {
    static user_login(data) {
        console.log(data);
        return axios.post(URL + "login/", data);
    }

}

