import React from 'react';
import "../../_assets/css/app.css";

class Forget extends React.Component{
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state ={
            email_address: "",
        }
    }
    handleChange = event => {this.setState({[event.target.name]: event.target.value});};
    onSubmit=(e)=>{
        e.preventDefault();
        if(this.state.email_address === ""){
            alert("Email address is required");
        }else {
            alert("Contact admin for assistance")
            this.setState({password: '', email_address: ''});
        }
    }
    render(){
        return(
            <div>
    
  <div id="app">
    <div className="page parallel">
      <div className="d-flex row">
        <div className="col-sm-3 white">
          <div className="p-5 mt-5 text-center">
            <h3><span style={{color: '#006189'}}>KCB</span>NILIPIE</h3>
          </div>
          <div className="p-5">
            <h3>Recover you <b>Password</b></h3>
            <p><small>It's easy to see usage, transactions, and do so much more, all in one place.</small></p>
            <form onSubmit={this.onSubmit}>
              <div className="form-group has-icon"><i className="icon-user-o" />
                <input type="email" name="email_address" value={this.state.email_address} className="form-control form-control-lg" placeholder="Email address" onChange={this.handleChange} />
              </div>
              
              <input type="submit" className="btn btn-primary btn-lg btn-block" style={{backgroundColor: '#006189'}} value="Log In" defaultValue="Log In" onSubmit={this.onSubmit} />
            </form>
            <p>
              <a href="/" style={{color: '#006699'}}>Login?</a>
            </p>
          </div>
        </div>
        <div className="col-sm-9  height-full accent-3 align-self-center text-center d-none d-md-block" data-bg-repeat="false" data-bg-possition="center" style={{padding: '15%', backgroundColor: '#006189'}}>
          <h1 className="text-white">KCBNILIPIE</h1>
          <p className="text-white" style={{marginTop: '30px'}}>We offer our customers, both individuals and businesses, an all in one digital payments platform, where they can perform numerous transactions using their own money or other people’s money, accessed by phone whether a smart phone or feature phone.</p>

          <p style={{marginTop: '30px', color: 'white',}}>nilipie.co.ke @ 2020. All rights reserved.</p>
        </div>
      </div>
    </div>
    <div className="control-sidebar-bg shadow white fixed"/>
  </div>
</div>

        )
    }
}

export default Forget;